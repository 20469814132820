import { registerApplication, start } from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from 'single-spa-layout';
import { cleanUpSessionStorage, getLaunchParams } from './utils/launch';
import { showExcept } from './utils/routes';

cleanUpSessionStorage();
getLaunchParams();

const routes = constructRoutes({
  routes: [
    {
      type: 'route',
      path: '/login',
      routes: [{ type: 'application', name: 'login' }]
    },
    {
      type: 'route',
      path: '/user-portal',
      routes: [{ type: 'application', name: 'user-portal' }]
    },
    {
      type: 'route',
      path: '/account-settings',
      routes: [{ type: 'application', name: 'accounts' }]
    },
    {
      type: 'route',
      path: '/cluster-manager',
      routes: [{ type: 'application', name: 'cluster-manager' }]
    },
    {
      type: 'route',
      exact: true,
      path: '/cluster-manager/windows',
      routes: [{ type: 'application', name: 'cluster-manager' }]
    },
    {
      type: 'route',
      path: '/conferences-admin',
      routes: [{ type: 'application', name: 'conferences-admin' }]
    }
  ],
  redirects: {
    '/': '/login'
  }
});

const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return System.import(`@2600hz/commio-${name}`);
  }
});

const layoutEngine = constructLayoutEngine({ routes, applications });

registerApplication({
  name: '@2600hz/commio-toolbar',
  app: () => System.import('@2600hz/commio-toolbar'),
  activeWhen: showExcept(['/login', '/cluster-manager/windows'])
});

registerApplication({
  name: 'commio-navbar',
  app: () => System.import('@2600hz/commio-navbar'),
  activeWhen: showExcept(['/login', '/cluster-manager/windows'])
});

applications.forEach(registerApplication);
start({ urlRerouteOnly: true });
